import { useReducer } from 'react';

import DataContext from './data-context.js';

const defaultDataState = {
    custom_item_35_tec_assesment: {
        initial: "eta",
        current: "eta",
        freezed: false
  },
    custom_item_02_type: {
        initial: "vertical",
        current: "vertical"
  },
    item_15_structural_asset: {
        initial: "2407.31",
        current: "2407.31"
  },
  item_10_name: {
    initial: "IV-100-Leca",
    current: "IV-100-Leca"
  },
  item_11_material_name: {
      initial: "N_LECA blockwork",
      current: "N_LECA blockwork"
  },
  item_12_material_category: {
      initial: "Concrete",
      current: "Concrete"
  },
  item_13_material_class: {
      initial: "Concrete",
      current: "Concrete"
  },  
    item_custom_material: {
        initial: "drywall",
        current: "drywall"
  },
    custom_item_07_length: {
        initial: "100",
        current: "100"
  },
    custom_item_14_fire_rating: {
        initial: "90",
        current: "90"
  },
    item_01_pfv_uniqueID: {
        initial: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9",
        current: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9"
  },
    item_custom_shape: {
        initial: "round",
        current: "round"
  },
    item_04_diameter: {
        initial: "70",
        current: "70"
  },
    item_05_height: {
        initial: "",
        current: ""
  },
    item_06_width: {
        initial: "",
        current: ""
  },
    item_17_MEP_element_uniqueID: {
      initial: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9",
      current: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9"
  },
    item_18_category: {
      initial: "",
      current: ""
  },
    item_19_description: {
      initial: "",
      current: ""
  },
    item_20_material_code: {
      initial: "",
      current: ""
  },

    item_custom_mep_shape: {
      initial: "round",
      current: "round"
  },
    item_24_diameter: {
       initial: "50",
      current: "50"
  },
  item_24_outer_diameter: {
    initial: "50",
    current: "50"
  },
  item_custom_annular_gap_width: {
    initial: "",
    current: ""
  },
  item_mep_elements_size_height: {
      initial: "",
      current: ""
  },
    item_23_width: {
      initial: "",
      current: ""
  },
    item_25_wall_thickness: {
      initial: "4",
      current: "4"
  },
    item_26_penetration_angle: {
      initial: "90",
      current: "90"
  },
    item_custom_penetrating_service_type: {
        initial: "Piping",
        current: "Piping"
  },
    item_custom_no_penetrating_services: {
        initial: "none",
        current: "none"
  },
  item_pipe_end_configuration: {
      initial: "",
      current: ""
  },
  max_dn_single_cable: {
      initial: "",
      current: ""
  },
  max_dn_cable_bundle: {
      initial: "",
      current: ""
  },
  max_dn_electro_inst_pipes: {
      initial: "",
      current: ""
  },
  item_28_insulation_uid: {
      initial: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9",
      current: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9"
  },
  item_custom_given_insulation_material: {
        initial: "none",
        current: "none"
  },
  item_34_given_insulation_thickness: {
      initial: "",
      current: "",
  },
  item_custom_given_insulation_configuration: {
      initial: "",
      current: ""
  },
  item_09_construction_element_uid: {
      initial: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9",
      current: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9"
  },
  item_custom_mep_penetration_id: {
      initial: "",
      current: ""
    },
  item_custom_mep_material_id: {
      initial: "",
      current: ""
  },
  item_custom_insulation_product_id: {
      initial: "",
      current: ""
  },
  item_custom_insulation_product_id: {
      initial: "",
      current: ""
  },
  custom_item_direction_x: {
      initial: "",
      current: ""
  },
  custom_item_direction_y: {
      initial: "",
      current: ""
  },
  custom_item_direction_z: {
      initial: "",
      current: ""
  },


  custom_item_location_x: {
      initial: "",
      current: ""
  },
  custom_item_location_y: {
      initial: "",
      current: ""
  },
  custom_item_location_z: {
      initial: "",
      current: ""
  },


  custom_item_mep_direction_x: {
      initial: "",
      current: ""
  },
  custom_item_mep_direction_y: {
      initial: "",
      current: ""
  },
  custom_item_mep_direction_z: {
      initial: "",
      current: ""
  },


  custom_item_mep_location_x: {
      initial: "z",
      current: ""
  },
  custom_item_mep_location_y: {
      initial: "",
      current: ""
  },
  custom_item_mep_location_z: {
      initial: "",
      current: ""
  }
};

const isBlank = (str) => {
  return (str === null || str === undefined || str.trim() === "");
}

const dataReducer = (state, action) => {
  const updatedValue = action.value

  if (action.type === 'SET_custom_item_35_tec_assesment'){  
    return {
      ...state,
      custom_item_35_tec_assesment: {
        initial: state.custom_item_35_tec_assesment.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'MAP_custom_item_35_tec_assesment'){
    let initVal = "";
    let freezed = false;
    let newValue = updatedValue ? updatedValue.toLowerCase() : "";

    if (newValue === "eta") {
      initVal = "eta";
      freezed = true;
    } else if (newValue === "abg") {
      initVal = "abg";
      freezed = true;
    } else {
      initVal = "eta";
      freezed = false;
    }  

    return {
      ...state,
      custom_item_35_tec_assesment: {
        initial: initVal,
        current: initVal,
        freezed: freezed
      }
    }
  }



  if (action.type === 'SET_custom_item_02_type'){
    let newValue = "";
    if (typeof updatedValue === 'string') {
      newValue = updatedValue.toLowerCase();
    } 
    if (newValue !== "") {
      return {
        ...state,
        custom_item_02_type: {
          initial: state.custom_item_02_type.initial,
          current: newValue
        }
      }
    }
  }

  if (action.type === 'MAP_custom_item_02_type'){
    let initVal = "";
    let freezed = false;
    let newValue = "";

    if (typeof updatedValue === 'string') {
      newValue = updatedValue.toLowerCase();
    } 

    if (newValue === "horizontal") {
      initVal = "wall";
      freezed = false;
    } else if (newValue === "vertical") {
      initVal = "floor";
      freezed = false;
    } 

    return {
      ...state,
      custom_item_02_type: {
        initial: initVal,
        current: initVal,
        freezed: freezed
      }
    }
  }



  if (action.type === 'SET_item_custom_material'){  
    return {
      ...state,
      item_custom_material: {
        initial: state.item_custom_material.initial,
        current: updatedValue
      }
    }
  }
  if (action.type === 'MAP_item_custom_material'){
    let help = parseInt(updatedValue);
    let initVal = "";

    if (help < 650) {
      initVal = "drywall";
    } else if (help >= 650) {
      initVal = "massive";
    } 

    return {
      ...state,
      item_custom_material: {
        initial: initVal,
        current: initVal,
        freezed: false
      }
    }
  }


  if (action.type === 'MAP_item_05_height'){
    const isNumeric = /^-?\d+(\.\d+)?$/.test(updatedValue);
    let initVal = (isNumeric === true)? parseFloat(updatedValue) : ""
    return {
      ...state,
      item_05_height: {
        initial: initVal,
        current: initVal,
        freezed: true
      }
    }
  }

  if (action.type === 'MAP_item_06_width'){
    const isNumeric = /^-?\d+(\.\d+)?$/.test(updatedValue);
    let initVal = (isNumeric === true)? parseFloat(updatedValue) : ""
    return {
      ...state,
      item_06_width: {
        initial: initVal,
        current: initVal,
        freezed: true
      }
    }
  }


  if (action.type === 'SET_custom_item_07_length'){  
    return {
      ...state,
      custom_item_07_length: {
        initial: state.custom_item_07_length.initial,
        current: updatedValue
      }
    }
  }
  if (action.type === 'MAP_custom_item_07_length'){
    let help = parseInt(updatedValue);
    let initVal = "";

    if (help >= 70) {
      initVal = "70";
    }
    if (help >= 90) {
      initVal = "90";
    } 
    if (help >= 100) {
      initVal = "100";
    } 
    if (help >= 150) {
      initVal = "150";
    } 
    if (help >= 200) {
      initVal = "200";
    } 

    return {
      ...state,
      custom_item_07_length: {
        initial: initVal,
        current: initVal,
        freezed: true
      }
    }
  }


  if (action.type === 'SET_custom_item_14_fire_rating'){  
    return {
      ...state,
      custom_item_14_fire_rating: {
        initial: state.custom_item_14_fire_rating.initial,
        current: updatedValue
      }
    }
  }
  if (action.type === 'MAP_custom_item_14_fire_rating'){

    const str = updatedValue ? updatedValue.toString() : "0";
    const numStr = str.match(/\d+/)[0];
    const help = parseInt(numStr);

    let initVal = "";

    if (help > 0) {
      initVal = "30";
    }
    if (help > 30) {
      initVal = "60";
    }
    if (help > 60) {
      initVal = "90";
    } 
    if (help > 90) {
      initVal = "120";
    } 
    if (help > 120) {
      initVal = "180";
    } 
    if (help > 180) {
      initVal = "240";
    } 

    return {
      ...state,
      custom_item_14_fire_rating: {
        initial: initVal,
        current: initVal,
        freezed: false
      }
    }
  }


  if (action.type === 'MAP_item_17_MEP_element_uniqueID'){  
    return {
      ...state,
      item_17_MEP_element_uniqueID: {
        initial: state.item_17_MEP_element_uniqueID.initial,
        current: updatedValue
      }
    }
  }

  
  if (action.type === 'MAP_item_18_category'){
    return {
      ...state,
      item_18_category: {
        initial: state.item_18_category.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'MAP_item_19_description'){
    return {
      ...state,
      item_19_description: {
        initial: state.item_19_description.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'MAP_item_20_material_code'){
    return {
      ...state,
      item_20_material_code: {
        initial: state.item_20_material_code.initial,
        current: updatedValue
      }
    }
  }


  if (action.type === 'MAP_item_custom_shape'){
    const isNumeric = /^-?\d+(\.\d+)?$/.test(updatedValue);

    let initVal = (isNumeric === true)? "round" : "rectangle"


    return {
      ...state,
      item_custom_shape: {
        initial: initVal,
        current: initVal,
        freezed: true
      }
    }
  }


  if (action.type === 'MAP_item_04_diameter'){
    const isNumeric = /^-?\d+(\.\d+)?$/.test(updatedValue);

    let initVal = (isNumeric === true)? parseFloat(updatedValue) : ""


    return {
      ...state,
      item_04_diameter: {
        initial: initVal,
        current: initVal,
        freezed: true
      }
    }
  }

  




  if (action.type === 'SET_item_custom_mep_shape'){  
    return {
      ...state,
      item_custom_mep_shape: {
        initial: state.item_custom_shape.initial,
        current: updatedValue
      }
    }
  }
  if (action.type === 'MAP_item_custom_mep_shape'){
    const isNumeric = /^-?\d+(\.\d+)?$/.test(updatedValue);

    let initVal = (isNumeric === true)? "round" : "rectangle"
    return {
      ...state,
      item_custom_mep_shape: {
        initial: initVal,
        current: initVal,
        freezed: true
      }
    }
  }




// #### Penetration service type

// Variable: item_custom_penetrating_service_type

// Links: "Penetration service type"
// Mitte: nichts
// Rechts: Buttons

// Zuordnungsvorschrift: 
// Hier kommt sehr wahrscheinlich im ersten Step nichts brauchbares in den Feldern 18-20. 
// Wenn jedoch genau die genannten Button-Begriffe geliefert werden, soll dies zugeordnet / ausgewählt werden. 
// Dies kann händisch ausgewählt oder verändert werden.
// Wird hier eine Auswahl getroffen, dann wird das Feld item_custom_no_penetrating_services auf den Wert "none" gesetzt,
// da es kein "empty Seal" mehr sein kann.

// Mögliche Auswahlen:
// - Alubond Pipes
// - Comb. Pipes
// - Non-comb. Pipes
// - Cable Bundle"
// - Electro Inst. Pipes
// - Cable Route
// - Lüftung rd
// - Lüftung eckig


  if (action.type === 'SET_item_custom_penetrating_service_type'){  
    return {
      ...state,
      item_custom_penetrating_service_type: {
        initial: state.item_custom_penetrating_service_type.initial,
        current: updatedValue
      },
      item_custom_no_penetrating_services: {
        initial: state.item_custom_no_penetrating_services.initial,
        current: 'none'
      },
      item_custom_mep_penetration_id: {
        initial: state.item_custom_mep_penetration_id.initial,
        current: ""
      },
      item_custom_mep_material_id: {
        initial: state.item_custom_mep_material_id.initial,
        current: ""
      }
    }
  }
  if (action.type === 'MAP_item_custom_penetrating_service_type'){

    return {
      ...state,
      item_custom_penetrating_service_type: {
        initial: "",
        current: "",
        freezed: false
      }
    }
  }

  



// #### No Penetration services

// Variable: item_custom_no_penetrating_services

// Links: "No penetration services"
// Mitte: nichts
// Rechts: Buttons

// Zuordnungsvorschrift: 
// Dies soll etwas separat dargestellt werden. 
// Nur wenn es keine ID in Feld 17 gibt, soll Empty Seal vorausgewählt werden. 
// Kann wieder abgewählt werden, bzw. wir automatisch abgewählt, wenn bei Penetration service type etwas ausgewählt wird.

// Mögliche Auswahlen:
// - Empty Seal
// - Leerschott


  if (action.type === 'SET_item_custom_no_penetrating_services'){  
    return {
      ...state,
      item_custom_no_penetrating_services: {
        initial: state.item_custom_no_penetrating_services.initial,
        current: updatedValue
      },
      item_custom_penetrating_service_type: {
        initial: state.item_custom_penetrating_service_type.initial,
        current: (updatedValue == "empty_seal") ? "" : state.item_custom_penetrating_service_type.current || 'not_specified'
      },
      item_custom_mep_penetration_id: {
        initial: state.item_custom_mep_penetration_id.initial,
        current: ""
      },
      item_custom_mep_material_id: {
        initial: state.item_custom_mep_material_id.initial,
        current: ""
      }
    }
  }
  if (action.type === 'MAP_item_custom_no_penetrating_services'){
    const field_17 = state.item_17_MEP_element_uniqueID.current;

    const preselection = (field_17 !== "" && field_17 !== undefined && field_17 !== null)? "none" : "empty_seal";

    return {
      ...state,
      item_custom_no_penetrating_services: {
        initial: preselection,
        current: preselection,
        freezed: true
      }
    }
  }

  if (action.type === 'MAP_item_mep_elements_size_height'){
    const isNumeric = /^-?\d+(\.\d+)?$/.test(updatedValue);
    let initVal = (isNumeric === true)? parseFloat(updatedValue) : ""
    return {
      ...state,
      item_mep_elements_size_height: {
        initial: initVal,
        current: initVal,
        freezed: true
      }
    }
  }

  if (action.type === 'MAP_item_23_width'){
    const isNumeric = /^-?\d+(\.\d+)?$/.test(updatedValue);
    let initVal = (isNumeric === true)? parseFloat(updatedValue) : ""
    return {
      ...state,
      item_23_width: {
        initial: initVal,
        current: initVal,
        freezed: true
      }
    }
  }
  
  if (action.type === 'MAP_item_24_diameter'){
    const isNumeric = /^-?\d+(\.\d+)?$/.test(updatedValue);
    let initVal = (isNumeric === true)? parseFloat(updatedValue) : ""
    return {
      ...state,
      item_24_diameter: {
        initial: initVal,
        current: initVal,
        freezed: true
      }
    }
  }

  if (action.type === 'MAP_item_24_outer_diameter'){
    const isNumeric = /^-?\d+(\.\d+)?$/.test(updatedValue);
    let initVal = (isNumeric === true)? parseFloat(updatedValue) : ""
    return {
      ...state,
      item_24_outer_diameter: {
        initial: initVal,
        current: initVal,
        freezed: true
      }
    }
  }



  if (action.type === 'MAP_item_25_wall_thickness'){  
    return {
      ...state,
      item_25_wall_thickness: {
        initial: state.item_25_wall_thickness.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'MAP_item_09_construction_element_uid'){  
    return {
      ...state,
      item_09_construction_element_uid: {
        initial: state.item_09_construction_element_uid.initial,
        current: updatedValue
      }
    }
  }


  



//   #### Penetrating angle

// Links: "Penetration angle [deg]"
// Mitte: Wert aus Feld 26
// Rechts: Wert aus Feld 26

// Entspricht „Zulässiger Winkel von/bis“ im PS, abprüfen beim Ergebnis.

  

  if (action.type === 'MAP_item_26_penetration_angle'){  
    return {
      ...state,
      item_26_penetration_angle: {
        initial: state.item_26_penetration_angle.initial,
        current: updatedValue
      }
    }
  }


  if (action.type === 'SET_item_pipe_end_configuration'){  
    return {
      ...state,
      item_pipe_end_configuration: {
        initial: state.item_pipe_end_configuration.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'SET_max_dn_single_cable'){
    return {
      ...state,
      max_dn_single_cable: {
        initial: state.max_dn_single_cable.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'SET_max_dn_cable_bundle'){
    return {
      ...state,
      max_dn_cable_bundle: {
        initial: state.max_dn_cable_bundle.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'SET_max_dn_electro_inst_pipes'){
    return {
      ...state,
      max_dn_electro_inst_pipes: {
        initial: state.max_dn_electro_inst_pipes.initial,
        current: updatedValue
      }
    }
  }


  if (action.type === 'SET_item_custom_given_insulation_material'){  
    return {
      ...state,
      item_custom_given_insulation_material: {
        initial: state.item_custom_given_insulation_material.initial,
        current: updatedValue
      },
      item_custom_insulation_product_id: {
        initial: state.item_custom_insulation_product_id.initial,
        current: ""
      }


    }
  }

  

  if (action.type === 'MAP_item_34_given_insulation_thickness'){
    return {
      ...state,
      item_34_given_insulation_thickness: {
        initial: updatedValue,
        current: updatedValue,
        freezed: true
      }
    }
  }

  if (action.type === 'MAP_item_custom_given_insulation_configuration'){
    const new_item_custom_given_insulation_configuration = (parseInt(updatedValue) > 0)? 'cs' : '';
    return {
      ...state,
      item_custom_given_insulation_configuration: {
        initial: '',
        current: new_item_custom_given_insulation_configuration,
        freezed: true
      }
    }
  }

  

  if (action.type === 'MAP_item_01_pfv_uniqueID'){
    return {
      ...state,
      item_01_pfv_uniqueID: {
        initial: updatedValue,
        current: updatedValue,
        freezed: true
      }
    }
  }


  if (action.type === 'MAP_insulation_material'){
    let val = "";
    if (isBlank(updatedValue['uid']) && isBlank(updatedValue['description']) && isBlank(updatedValue['national_code']) && isBlank(updatedValue['material_code']) && isBlank(updatedValue['coating_material_code'])) {
      val = "none";
    } else if (updatedValue['uid'] === "flex_elast_foam" || updatedValue['uid'] === "mineral_wool" || updatedValue['uid'] === "pe") {
      val = updatedValue['uid'];
    } else if (updatedValue['description'] === "flex_elast_foam" || updatedValue['description'] === "mineral_wool" || updatedValue['description'] === "pe") {
      val = updatedValue['description'];
    } else if (updatedValue['national_code'] === "flex_elast_foam" || updatedValue['national_code'] === "mineral_wool" || updatedValue['national_code'] === "pe") {
      val = updatedValue['national_code'];
    } else if (updatedValue['material_code'] === "flex_elast_foam" || updatedValue['material_code'] === "mineral_wool" || updatedValue['material_code'] === "pe") {
      val = updatedValue['material_code'];
    } else if (updatedValue['coating_material_code'] === "flex_elast_foam" || updatedValue['coating_material_code'] === "mineral_wool" || updatedValue['coating_material_code'] === "pe") {
      val = updatedValue['coating_material_code'];
    } else {
      val = "not_specified";
    }

    return {
      ...state,
      item_custom_given_insulation_material: {
        initial: state.item_custom_given_insulation_material.initial,
        current: val,
        freezed: false
      }
    }
  }




  

  if (action.type === 'SET_item_custom_mep_penetration_id'){  
    return {
      ...state,
      item_custom_mep_penetration_id: {
        initial: state.item_custom_mep_penetration_id.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'SET_item_custom_mep_material_id'){  
    return {
      ...state,
      item_custom_mep_material_id: {
        initial: state.item_custom_mep_material_id.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'SET_item_custom_insulation_product_id'){
    return {
      ...state,
      item_custom_insulation_product_id: {
        initial: state.item_custom_insulation_product_id.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'SET_custom_item_direction_x'){
    return {
      ...state,
      custom_item_direction_x: {
        initial: state.custom_item_direction_x.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'MAP_custom_item_direction_x'){
    return {
      ...state,
      custom_item_direction_x: {
        initial: updatedValue,
        current: updatedValue,
        freezed: true
      }
    }
  }

  if (action.type === 'SET_custom_item_direction_y'){
    return {
      ...state,
      custom_item_direction_y: {
        initial: state.custom_item_direction_y.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'MAP_custom_item_direction_y'){
    return {
      ...state,
      custom_item_direction_y: {
        initial: updatedValue,
        current: updatedValue,
        freezed: true
      }
    }
  }

  if (action.type === 'SET_custom_item_direction_z'){
    return {
      ...state,
      custom_item_direction_z: {
        initial: state.custom_item_direction_z.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'MAP_custom_item_direction_z'){
    return {
      ...state,
      custom_item_direction_z: {
        initial: updatedValue,
        current: updatedValue,
        freezed: true
      }
    }
  }

  if (action.type === 'SET_custom_item_location_x'){
    return {
      ...state,
      custom_item_location_x: {
        initial: state.custom_item_location_x.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'MAP_custom_item_location_x'){
    return {
      ...state,
      custom_item_location_x: {
        initial: updatedValue,
        current: updatedValue,
        freezed: true
      }
    }
  }



  if (action.type === 'SET_custom_item_location_y'){
    return {
      ...state,
      custom_item_location_y: {
        initial: state.custom_item_location_y.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'MAP_custom_item_location_y'){
    return {
      ...state,
      custom_item_location_y: {
        initial: updatedValue,
        current: updatedValue,
        freezed: true
      }
    }
  }


  if (action.type === 'SET_custom_item_location_z'){
    return {
      ...state,
      custom_item_location_z: {
        initial: state.custom_item_location_z.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'MAP_custom_item_location_z'){
    return {
      ...state,
      custom_item_location_z: {
        initial: updatedValue,
        current: updatedValue,
        freezed: true
      }
    }
  }



  if (action.type === 'SET_custom_item_mep_direction_x'){
    return {
      ...state,
      custom_item_mep_direction_x: {
        initial: state.custom_item_mep_direction_x.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'MAP_custom_item_mep_direction_x'){
    return {
      ...state,
      custom_item_mep_direction_x: {
        initial: updatedValue,
        current: updatedValue,
        freezed: true
      }
    }
  }



  if (action.type === 'SET_custom_item_mep_direction_y'){
    return {
      ...state,
      custom_item_mep_direction_y: {
        initial: state.custom_item_mep_direction_y.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'MAP_custom_item_mep_direction_y'){
    return {
      ...state,
      custom_item_mep_direction_y: {
        initial: updatedValue,
        current: updatedValue,
        freezed: true
      }
    }
  }



  if (action.type === 'SET_custom_item_mep_direction_z'){
    return {
      ...state,
      custom_item_mep_direction_z: {
        initial: state.custom_item_mep_direction_z.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'MAP_custom_item_mep_direction_z'){
    return {
      ...state,
      custom_item_mep_direction_z: {
        initial: updatedValue,
        current: updatedValue,
        freezed: true
      }
    }
  }


  if (action.type === 'SET_custom_item_mep_location_x'){
    return {
      ...state,
      custom_item_mep_location_x: {
        initial: state.custom_item_mep_location_x.initial,
        current: updatedValue
      }
    }
  }
  
  if (action.type === 'MAP_custom_item_mep_location_x'){
    return {
      ...state,
      custom_item_mep_location_x: {
        initial: updatedValue,
        current: updatedValue,
        freezed: true
      }
    }
  }


  if (action.type === 'SET_custom_item_mep_location_y'){
    return {
      ...state,
      custom_item_mep_location_y: {
        initial: state.custom_item_mep_location_y.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'MAP_custom_item_mep_location_y'){
    return {
      ...state,
      custom_item_mep_location_y: {
        initial: updatedValue,
        current: updatedValue,
        freezed: true
      }
    }
  }



  if (action.type === 'SET_custom_item_mep_location_z'){
    return {
      ...state,
      custom_item_mep_location_z: {
        initial: state.custom_item_mep_location_z.initial,
        current: updatedValue
      }
    }
  }

  if (action.type === 'MAP_custom_item_mep_location_z'){
    return {
      ...state,
      custom_item_mep_location_z: {
        initial: updatedValue,
        current: updatedValue,
        freezed: true
      }
    }
  }


  return state;
};



const DataProvider = props => {
    const [dataState, dispatchDataAction] = useReducer(dataReducer, defaultDataState);
    
    // Das ist die Funktion, die ausgelöst wird, wenn im Readjustment eine Änderung vorgenommen wird.
    // Hier werden also Werte, die vorher durch das Mapping gesetzt wurden, überschrieben.
    // Hier werden nur die Werte aufgeführt, die auch wirklich durch Buttons im Readjustment geändert werden können.

    const set_data_value = (name, value) => {
      if (name === 'custom_item_35_tec_assesment') {
        return dispatchDataAction({type: 'SET_custom_item_35_tec_assesment', value: value});
      } else if (name === 'custom_item_02_type') {
        dispatchDataAction({type: 'SET_custom_item_02_type', value: value});
      } else if (name === 'item_custom_material') {
        dispatchDataAction({type: 'SET_item_custom_material', value: value});
      } else if (name === 'custom_item_07_length') {
        dispatchDataAction({type: 'SET_custom_item_07_length', value: value});
      } else if (name === 'custom_item_14_fire_rating') {
        dispatchDataAction({type: 'SET_custom_item_14_fire_rating', value: value});

        // SET_item_17_MEP_element_uniqueID, da es nicht änderbar ist 
        // SET_item_custom_shape gibt es nicht, da es errechnet wird.
        // SET_item_custom_mep_shape gibt es nicht, da es errechnet wird.

      } else if (name === 'item_custom_penetrating_service_type') {
        dispatchDataAction({type: 'SET_item_custom_penetrating_service_type', value: value});
      } else if (name === 'item_custom_no_penetrating_services') {
        dispatchDataAction({type: 'SET_item_custom_no_penetrating_services', value: value});

        // SET_item_26_penetration_angle gibt es nicht, da es nicht änderbar ist
        // item_09_construction_element_uid gibt es nihct, da es nicht änderbar ist
        // item_01_pfv_uniqueID gibt es nihct, da es nicht änderbar ist
        // item_28_insulation_uid gibt es nihct, da es nicht änderbar ist

      } else if (name === 'item_pipe_end_configuration') {
        dispatchDataAction({type: 'SET_item_pipe_end_configuration', value: value});
      } else if (name === 'max_dn_single_cable') {
        dispatchDataAction({type: 'SET_max_dn_single_cable', value: value});
      } else if (name === 'max_dn_cable_bundle') {
        dispatchDataAction({type: 'SET_max_dn_cable_bundle', value: value});
      } else if (name === 'max_dn_electro_inst_pipes') {
        dispatchDataAction({type: 'SET_max_dn_electro_inst_pipes', value: value});
      } else if (name === 'item_custom_given_insulation_material') {
        dispatchDataAction({type: 'SET_item_custom_given_insulation_material', value: value});
      } else if (name === 'item_custom_mep_penetration_id') {
        dispatchDataAction({type: 'SET_item_custom_mep_penetration_id', value: value});
      } else if (name === 'item_custom_mep_material_id') {
        dispatchDataAction({type: 'SET_item_custom_mep_material_id', value: value});
      } else if (name === 'item_custom_insulation_product_id') {
        dispatchDataAction({type: 'SET_item_custom_insulation_product_id', value: value});

      } else if (name === 'custom_item_direction_x') {
        dispatchDataAction({type: 'SET_custom_item_direction_x', value: value});
      } else if (name === 'custom_item_direction_y') {
        dispatchDataAction({type: 'SET_custom_item_direction_y', value: value});
      } else if (name === 'custom_item_direction_z') {
        dispatchDataAction({type: 'SET_custom_item_direction_z', value: value});

      } else if (name === 'custom_item_location_x') {
        dispatchDataAction({type: 'SET_custom_item_location_x', value: value});
      } else if (name === 'custom_item_location_y') {
        dispatchDataAction({type: 'SET_custom_item_location_y', value: value});
      } else if (name === 'custom_item_location_z') {
        dispatchDataAction({type: 'SET_custom_item_location_z', value: value});

      } else if (name === 'custom_item_mep_direction_x') {
        dispatchDataAction({type: 'SET_custom_item_mep_direction_x', value: value});
      } else if (name === 'custom_item_mep_direction_y') {
        dispatchDataAction({type: 'SET_custom_item_mep_direction_y', value: value});
      } else if (name === 'custom_item_mep_direction_z') {
        dispatchDataAction({type: 'SET_custom_item_mep_direction_z', value: value});

      } else if (name === 'custom_item_mep_location_x') {
        dispatchDataAction({type: 'SET_custom_item_mep_location_x', value: value});
      } else if (name === 'custom_item_mep_location_y') {
        dispatchDataAction({type: 'SET_custom_item_mep_location_y', value: value});
      } else if (name === 'custom_item_mep_location_z') {
        dispatchDataAction({type: 'SET_custom_item_mep_location_z', value: value});
      }

      // SET_item_mep_elements_size_height gibt es nicht, da es nicht änderbar ist.
      // SET_item_26_penetration_angle gibt es nicht, da es nicht änderbar ist.
    
    };


    // Das ist die Funktion die ausgelöst wird, wenn man von der Ansicht der Importierten Daten auf 
    // die Ansicht des Readjustments wechselt, wo also nochmal nachträglich Änderungen vorgenommen werden können.

    const doMapping = (items) => {
      dispatchDataAction({type: 'MAP_custom_item_35_tec_assesment', value: items.custom_item_35_tec_assesment});
      dispatchDataAction({type: 'MAP_custom_item_02_type', value: items.custom_item_02_type});
      dispatchDataAction({type: 'MAP_item_custom_material', value: items.custom_item_15_structural_asset}); // Berechnung durch anderes Feld
      dispatchDataAction({type: 'MAP_custom_item_07_length', value: items.custom_item_07_length});
      dispatchDataAction({type: 'MAP_custom_item_14_fire_rating', value: items.custom_item_14_fire_rating});
      dispatchDataAction({type: 'MAP_item_custom_shape', value: items.custom_item_04_diameter});  // Berechnung durch anderes Feld
      dispatchDataAction({type: 'MAP_item_04_diameter', value: items.custom_item_04_diameter});  
      dispatchDataAction({type: 'MAP_item_05_height', value: items.custom_item_05_height});
      dispatchDataAction({type: 'MAP_item_06_width', value: items.custom_item_06_width});
      dispatchDataAction({type: 'MAP_item_17_MEP_element_uniqueID', value: items.custom_item_17_MEP_element_uniqueID}); 
      dispatchDataAction({type: 'MAP_item_18_category', value: items.custom_item_18_category}); 
      dispatchDataAction({type: 'MAP_item_19_description', value: items.custom_item_19_description});
      dispatchDataAction({type: 'MAP_item_20_material_code', value: items.custom_item_20_material_code});
      dispatchDataAction({type: 'MAP_item_custom_mep_shape', value: items.custom_item_24_diameter});  // Berechnung durch anderes Feld
      dispatchDataAction({type: 'MAP_item_custom_penetrating_service_type', value: ""});  
      dispatchDataAction({type: 'MAP_item_custom_no_penetrating_services', value: items.custom_item_custom_no_penetrating_services}); 
      dispatchDataAction({type: 'MAP_item_mep_elements_size_height', value: items.custom_item_mep_elements_size_height});
      dispatchDataAction({type: 'MAP_item_23_width', value: items.custom_item_23_width});
      dispatchDataAction({type: 'MAP_item_24_diameter', value: items.custom_item_24_diameter});  
      dispatchDataAction({type: 'MAP_item_24_outer_diameter', value: items.custom_item_24_outer_diameter});  
      dispatchDataAction({type: 'MAP_item_25_wall_thickness', value: items.custom_item_25_wall_thickness}); 
      dispatchDataAction({type: 'MAP_item_26_penetration_angle', value: items.custom_item_26_penetration_angle}); 
      dispatchDataAction({type: 'MAP_item_34_given_insulation_thickness', value: items.custom_item_34_given_insulation_thickness}); 
      dispatchDataAction({type: 'MAP_item_09_construction_element_uid', value: items.custom_item_09_construction_element_uid}); 
      dispatchDataAction({type: 'MAP_item_01_pfv_uniqueID', value: items.custom_item_01_pfv_uniqueID});
      dispatchDataAction({type: 'MAP_item_28_insulation_uid', value: items.custom_item_28_insulation_uid});
      dispatchDataAction({type: 'MAP_insulation_material', value: items.compute_insulation_material});
      dispatchDataAction({type: 'MAP_item_custom_given_insulation_configuration', value: items.custom_item_34_given_insulation_thickness});  // Berechnung durch anderes Feld
      dispatchDataAction({type: 'MAP_custom_item_direction_x', value: items.custom_item_direction_x});
      dispatchDataAction({type: 'MAP_custom_item_direction_y', value: items.custom_item_direction_y});
      dispatchDataAction({type: 'MAP_custom_item_direction_z', value: items.custom_item_direction_z});
      dispatchDataAction({type: 'MAP_custom_item_location_x', value: items.custom_item_location_x});
      dispatchDataAction({type: 'MAP_custom_item_location_y', value: items.custom_item_location_y});
      dispatchDataAction({type: 'MAP_custom_item_location_z', value: items.custom_item_location_z});
      dispatchDataAction({type: 'MAP_custom_item_mep_direction_x', value: items.custom_item_mep_direction_x});
      dispatchDataAction({type: 'MAP_custom_item_mep_direction_y', value: items.custom_item_mep_direction_y});
      dispatchDataAction({type: 'MAP_custom_item_mep_direction_z', value: items.custom_item_mep_direction_z});
      dispatchDataAction({type: 'MAP_custom_item_mep_location_x', value: items.custom_item_mep_location_x});
      dispatchDataAction({type: 'MAP_custom_item_mep_location_y', value: items.custom_item_mep_location_y});
      dispatchDataAction({type: 'MAP_custom_item_mep_location_z', value: items.custom_item_mep_location_z});
    }

    const dataContext = {
      custom_item_35_tec_assesment: dataState.custom_item_35_tec_assesment,
      custom_item_02_type: dataState.custom_item_02_type,
      item_10_name: dataState.item_10_name,
      item_11_material_name: dataState.item_11_material_name,
      item_12_material_category: dataState.item_12_material_category,
      item_13_material_class: dataState.item_13_material_class,
      item_custom_material: dataState.item_custom_material,
      custom_item_14_fire_rating: dataState.custom_item_14_fire_rating,
      item_01_pfv_uniqueID: dataState.item_01_pfv_uniqueID,
      item_custom_shape: dataState.item_custom_shape,
      item_04_diameter: dataState.item_04_diameter,
      item_05_height: dataState.item_05_height,
      item_06_width: dataState.item_06_width,
      custom_item_07_length: dataState.custom_item_07_length,
      item_17_MEP_element_uniqueID: dataState.item_17_MEP_element_uniqueID,
      item_18_category: dataState.item_18_category,
      item_19_description: dataState.item_19_description,
      item_20_material_code: dataState.item_20_material_code,
      item_custom_mep_shape: dataState.item_custom_mep_shape,
      item_24_diameter: dataState.item_24_diameter,
      item_24_outer_diameter: dataState.item_24_outer_diameter,
      item_custom_annular_gap_width: dataState.item_custom_annular_gap_width,
      item_mep_elements_size_height: dataState.item_mep_elements_size_height,
      item_23_width: dataState.item_23_width,
      item_25_wall_thickness: dataState.item_25_wall_thickness,
      item_26_penetration_angle: dataState.item_26_penetration_angle,
      item_custom_penetrating_service_type: dataState.item_custom_penetrating_service_type,
      item_custom_no_penetrating_services: dataState.item_custom_no_penetrating_services,
      item_pipe_end_configuration: dataState.item_pipe_end_configuration,
      max_dn_single_cable: dataState.max_dn_single_cable,
      max_dn_cable_bundle: dataState.max_dn_cable_bundle,
      max_dn_electro_inst_pipes: dataState.max_dn_electro_inst_pipes,
      item_28_insulation_uid: dataState.item_28_insulation_uid,
      item_custom_given_insulation_material: dataState.item_custom_given_insulation_material,
      item_34_given_insulation_thickness: dataState.item_34_given_insulation_thickness,
      item_custom_given_insulation_configuration: dataState.item_custom_given_insulation_configuration,
      item_09_construction_element_uid: dataState.item_09_construction_element_uid,
      item_28_insulation_uid: dataState.item_28_insulation_uid,
      item_custom_mep_penetration_id: dataState.item_custom_mep_penetration_id,
      item_custom_mep_material_id: dataState.item_custom_mep_material_id,
      item_custom_insulation_product_id: dataState.item_custom_insulation_product_id,

      custom_item_direction_x: dataState.custom_item_direction_x,
      custom_item_direction_y: dataState.custom_item_direction_y,
      custom_item_direction_z: dataState.custom_item_direction_z,

      custom_item_location_x: dataState.custom_item_location_x,
      custom_item_location_y: dataState.custom_item_location_y,
      custom_item_location_z: dataState.custom_item_location_z,

      custom_item_mep_direction_x: dataState.custom_item_mep_direction_x,
      custom_item_mep_direction_y: dataState.custom_item_mep_direction_y,
      custom_item_mep_direction_z: dataState.custom_item_mep_direction_z,

      custom_item_mep_location_x: dataState.custom_item_mep_location_x,
      custom_item_mep_location_y: dataState.custom_item_mep_location_y,
      custom_item_mep_location_z: dataState.custom_item_mep_location_z,

      doDataMapping: doMapping,
      set_data_value: set_data_value
    };

    return <DataContext.Provider value={dataContext}>
        {props.children}
    </DataContext.Provider>
};

export default DataProvider;